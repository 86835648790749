/**
 * Display a FontAwesome icon
 * @param {string} classes - CSS classes
 * @param {string} style - Direct style parameters
 * @param {string} title - Title to set
 * @returns {string} - HTML code
 */
function icon(classes, style, title) {
	var html = '<span class="' + classes + '"'

	if (typeof style !== 'undefined' && style !== '') {
		html += ' style="' + style + '"'
	}
	if (typeof title !== 'undefined' && title !== '') {
		html += ' title="' + title + '"'
	}
	html += '>&zwnj;</span>'

	return html
}

/*
 * Routing
 */

/**
 * Redirects browser to specified URL
 * @param {string} url - URL to go to
 * @param {boolean} relative - URL is within the site or not
 * @returns {boolean} - Always false
 */
function go(url, relative) {
	var base = ''

	if (typeof relative === 'undefined' || relative === true) {
		base = base_url
	}

	document.location.href = base + url

	return false
}

/**
 * Jumps back a page in browsing histroy
 * @returns {boolean} - Always false
 */
function go_back() {
	window.history.back()

	return false
}
