"use strict"

// Store token for AJAX requests
var csrf_token

$(function() {
	// Read token when page is loaded
	csrf_token = document.head.querySelector('meta[name="csrf-token"]').content
	
	// Set CSRF token for in the header for all AJAX requests
	$.ajaxSetup({
		beforeSend: function(xhr) {
			xhr.setRequestHeader('X-CSRF-TOKEN', csrf_token)
		}
	})
})
