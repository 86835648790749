let map, map_view
let hotelSource, hotelLayer

function initMap() {
	let hotelFeatures = [ ]
	const hotelIconStyle = new ol.style.Style({
		image: new ol.style.Icon({
			anchor: [ 0.5, 0.9 ],
			src: base_url + 'images/map-hotel-marker.png',
			crossOrigin: '',
			scale: [ 1.0, 1.0 ]
		})
	})

	for (let i in hotels) {
		let hotel = hotels[i]

		hotelFeatures.push(new ol.Feature({
			geometry: new ol.geom.Point(ol.proj.fromLonLat([ hotel.lng, hotel.lat ])),
			name: hotel.name,
			description: hotel.description,
			image: hotel.image
		}))
	}

	hotelSource = new ol.source.Vector({
		features: hotelFeatures
	})
	hotelLayer = new ol.layer.Vector({
		source: hotelSource,
		style: hotelIconStyle
	})

	map_view = new ol.View({
		center: ol.proj.fromLonLat([ center.lng, center.lat ]),
		zoom: 12
	})

	map = new ol.Map({
		target: 'map',
		layers: [
			new ol.layer.Tile({
				source: new ol.source.OSM()
			}),
			hotelLayer
		],
		view: map_view,
		controls: [
			new ol.control.FullScreen(),
			new ol.control.Zoom()
		]
	})

	// Handle clicks
	map.on('click', function(e) {
		const pixel = map.getEventPixel(e.originalEvent)
		const hit = map.hasFeatureAtPixel(pixel)

		if (!hit) {
			popupOverlay.element.hidden = true

			return
		}

		// Get all features
		const featureList = map.getFeaturesAtPixel(pixel)
		let feature = featureList[0]
		let html = '<h3>' + feature.get('name') + '</h3>'

		if (feature.get('image')) {
			html += '<img src="' + feature.get('image') + '" alt="' + feature.get('name') + '">'
		}
		html += '<p>' + feature.get('description') + '</p>'

		container.innerHTML = html
		popupOverlay.element.hidden = false
		popupOverlay.setPosition(e.coordinate)
	})

	// Setup popup
	const container = document.getElementById('map-popup')
	const popupOverlay = new ol.Overlay({
		element: container,
		positioning: 'bottom-center',
		offset: [ 0, -10 ],
		autoPan: { animation: { duration: 250 } }
	})

	map.addOverlay(popupOverlay)
}

$(function() {
	/**
	 * Mobile nav toggle
	 */
	$('.mobile-nav-toggle').on('click', function(e) {
		$('#navbar').toggleClass('navbar-mobile')
	})

	/**
	 * Mobile nav dropdowns activate
	 */
	$('.navbar .dropdown > a').on('click', function(e) {
		if ($('#navbar').hasClass('navbar-mobile')) {
			e.preventDefault()
			this.nextElementSibling.classList.toggle('dropdown-active')
		}
	})

	/**
	 * Show/hide top line of header
	 * @returns {undefined}
	 */
	setInterval(() => {
		if (window.scrollY > 100) {
			$('#topbar').addClass('off')
		}
		else {
			$('#topbar').removeClass('off')
		}
	}, 417)

	/**
	 * Initialize GLightbox for posts
	 */
	if ($('.page-post').length > 0) {

	}
})

document.addEventListener('DOMContentLoaded', function() {
	const lightbox = GLightbox()

	// Initialize map if found on the page
	if (document.querySelector('#map')) {
		initMap()

		$('.center-map').on('click', function() {
			let lat = parseFloat($(this).attr('data-lat'))
			let lng = parseFloat($(this).attr('data-lng'))

			map_view.setCenter(ol.proj.fromLonLat([ lng, lat ]))

			return false
		})
	}
})
